import { QueryParamAdapter, QueryParamAdapterComponent } from "use-query-params";
import { useLocation } from "react-router-dom";
import { useSlideContext } from "./SlideContext";

export const BannerAdapter: QueryParamAdapterComponent = ({ children }) => {
  const { slide } = useSlideContext();
  const location = useLocation();

  const adapter: QueryParamAdapter = {
    replace() {},
    push() {},
    get location() {
      return {
        ...location,
        search: slide.searchTermString ?? "",
      };
    },
  };

  return children(adapter);
};
