import React from "react";
import { createRoot } from "react-dom/client";
import WhitelabelApp from "./WhitelabelApp";
import "./core/sentry-init";
import "iframe-resizer/js/iframeResizer.contentWindow.js";

import "./template/public/assets/css/styles.min.css";
import "./template/public/assets/css/styles-whitelabel.min.css";
import "./core/customStyles.scss";

const root = createRoot(document.getElementById("root")!);
root.render(<WhitelabelApp />);
