import React, { useMemo } from "react";
import App from "../../../App";
import WhitelabelRoutes from "../../../router/components/WhitelabelRoutes";
import { CarCardContext, CarCardContextType } from "../../../modules/vehicle/components/CarCard";
import useStableFunction from "../../../modules/common/hooks/useStableFunction";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import WhitelabelTheme from "./WhitelabelTheme";

const WhitelabelFull: React.FC = () => {
  const { whitelabelVehicleDetailPath } = usePathBuilder();

  // get stable reference to path builder
  const vehicleDetailPathBuilder = useStableFunction(whitelabelVehicleDetailPath);
  // use memo to avoid forcing re-rendering of car card here
  const carCardContext: CarCardContextType = useMemo(
    () => ({ linkBuilder: vehicleDetailPathBuilder, enableCompare: false, enableFavorites: false }),
    [vehicleDetailPathBuilder],
  );

  return (
    <CarCardContext.Provider value={carCardContext}>
      <App>
        <WhitelabelTheme />
        <WhitelabelRoutes />
      </App>
    </CarCardContext.Provider>
  );
};

export default WhitelabelFull;
