import React, { useEffect, useState } from "react";
import initI18next from "../../../core/i18next/scripts/init";
import { useWhitelabelConfig } from "./WhitelabelConfig";

type Props = {
  children: React.ReactNode;
};

/**
 * The children, primarily LanguageGuard must not be rendered until initI18next is called.
 * Otherwise, it won't be able to obtain the global i18n instance.
 * @param children
 * @constructor
 */
const WhitelabelLanguageConfig: React.FC<Props> = ({ children }) => {
  const [i18nConfigured, setI18nConfigured] = useState(false);
  const config = useWhitelabelConfig();
  useEffect(() => {
    initI18next(config.language);
    setI18nConfigured(true);
  }, [config]);

  if (!i18nConfigured) {
    return null;
  }

  return <>{children}</>;
};

export default WhitelabelLanguageConfig;
