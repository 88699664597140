import { gql } from "graphql-request";
import { Whitelabel } from "../vo";
import { hygraphApi } from "./hygraphApi";

type WhitelabelData = {
  whitelabel: Whitelabel;
};

export const whitelabelApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    getWhitelabel: builder.query<WhitelabelData | null, string>({
      providesTags: ["languageDependent"],
      query: id => {
        return {
          document: gql`
            query WhitelabelData($id: ID!) {
              whitelabel(where: { id: $id }) {
                id
                utmSource
              }
            }
          `,
          variables: {
            id,
          },
        };
      },
    }),
  }),
});

export const { useGetWhitelabelQuery } = whitelabelApi;
