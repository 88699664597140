import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MessageTypes, sendMessage } from "../services/messages";

const WhitelabelHashUpdater: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    sendMessage(window.parent, {
      type: MessageTypes.HASHCHANGE,
      payload: window.location.hash,
    });
  }, [location]);

  return null;
};

export default WhitelabelHashUpdater;
