import App from "../../../App";
import Banner from "../../banner/components/Banner";

const WhitelabelBanner: React.FC = () => {
  return (
    <App>
      <Banner />
    </App>
  );
};

export default WhitelabelBanner;
