import React, { ReactNode } from "react";
import { createNullableContext } from "../../../modules/common/helpers/contextCreator";
import { BannerSlide } from "../../../modules/hygraph/vo";

type SlideContextValue = {
  slide: BannerSlide;
};

export const [SlideContext, useSlideContext] = createNullableContext<SlideContextValue>("SlideContext");

export const SlideContextProvider: React.FC<SlideContextValue & { children: ReactNode }> = ({ slide, children }) => {
  return (
    <SlideContext.Provider
      value={{
        slide,
      }}
    >
      {children}
    </SlideContext.Provider>
  );
};
