import { gql } from "graphql-request";
import imageFragment from "./imageFragment";
import linkToUnionFragment from "./linkToUnionFragment";

const bannerFragment = gql`
  ${imageFragment}
  ${linkToUnionFragment}
  fragment Banner on Banner {
    id
    utmSource
    domains
    slides {
      id
      searchTermString
      title
      ctaLabel
      ctaLink {
        to {
          ...LinkToUnion
        }
      }
      displayDuration
      imageDesktop {
        ...ImageParts
      }
      imageMobile {
        ...ImageParts
      }
    }
  }
`;

export default bannerFragment;
