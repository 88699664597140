import { useEffect, useMemo, useRef } from "react";
import { QueryParamProvider } from "use-query-params";
import { useGetBannerQuery } from "../../../modules/hygraph/services/bannerApi";
import { useBannerWhitelabelConfig } from "../../core/components/WhitelabelConfig";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import useStableFunction from "../../../modules/common/hooks/useStableFunction";
import { CarCardContextType } from "../../../modules/vehicle/components/CarCard";
import { setUtmParams, UtmCampaignType } from "../../core/services/setUtmParams";
import { useBannerSlider } from "../../../modules/banner/hooks/useBannerSlider";
import BannerNav from "../../../modules/banner/components/BannerNav";
import WhitelabelBannerContainer from "../../core/components/WhitelabelBannerContainer";
import BannerSlide from "./BannerSlide";
import { BannerAdapter } from "./BannerAdapter";
import { SlideContextProvider } from "./SlideContext";

const ADVERTISING_ROW_HEIGHT = "--advertising-row-height";

const Banner = () => {
  const whitelabelConfig = useBannerWhitelabelConfig();
  const wrapRef = useRef<HTMLDivElement>(null);
  const { data } = useGetBannerQuery(whitelabelConfig.id);
  const { activeSlide, setActiveSlide, listWrapRef, slideRef, slidesRef, pause, resume } = useBannerSlider(
    data?.banner.slides,
  );
  const { searchPath, homePath } = usePathBuilder();
  const setCardHeight = () => {
    wrapRef.current?.style.setProperty(ADVERTISING_ROW_HEIGHT, "");

    const slideHeights = [...slidesRef.current].map(slideEl => {
      return slideEl[1].offsetHeight;
    });

    if (slideHeights.length === data?.banner.slides.length) {
      wrapRef.current?.style.setProperty(ADVERTISING_ROW_HEIGHT, `${Math.max(...slideHeights)}px`);
    }
  };

  const resize = useStableFunction(() => {
    const slideEl = slidesRef.current.get(0);

    if (slideEl) {
      setCardHeight();
    }
  });

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [resize]);

  const { vehicleDetailPath } = usePathBuilder();

  const utmParams = {
    source: data?.banner.utmSource,
    campaign: UtmCampaignType.Banner,
  };

  const vehicleDetailPathBuilder = useStableFunction(({ id, slug }: { id: number; slug: string }) => {
    const detailPath = vehicleDetailPath({
      absolute: true,
      id,
      slug,
    });

    return setUtmParams(detailPath, utmParams);
  });

  const carCardContext: CarCardContextType = useMemo(
    () => ({
      linkBuilder: vehicleDetailPathBuilder,
      enableCompare: false,
      enableFavorites: false,
      linkTarget: "_blank",
    }),
    [vehicleDetailPathBuilder],
  );

  return (
    <WhitelabelBannerContainer
      carCardContext={carCardContext}
      containerRef={wrapRef}
      homeLink={setUtmParams(homePath({ absolute: true }), utmParams)}
      allVehiclesLink={setUtmParams(
        searchPath({
          absolute: true,
        }),
        utmParams,
      )}
    >
      <div className="advertising-dot-wrap">
        <BannerNav activeSlide={activeSlide} setActiveSlide={setActiveSlide} slides={data?.banner.slides} />
      </div>
      <div
        ref={listWrapRef}
        className="advertising__list-wrap"
        onBlur={resume}
        onFocus={pause}
        onMouseOut={resume}
        onMouseOver={pause}
        onTouchEnd={resume}
        onTouchStart={pause}
      >
        {data?.banner.slides.map((slide, key) => (
          <SlideContextProvider key={key} slide={slide}>
            <QueryParamProvider adapter={BannerAdapter}>
              <BannerSlide
                ref={node => {
                  slideRef(node, key);
                }}
                utmParams={utmParams}
              />
            </QueryParamProvider>
          </SlideContextProvider>
        ))}
      </div>
    </WhitelabelBannerContainer>
  );
};

export default Banner;
