import React from "react";
import { useFullWhitelabelConfig } from "../../core/components/WhitelabelConfig";
import DealerVehicleSearch from "./DealerVehicleSearch";

const Overview: React.FC = () => {
  const whitelabelConfig = useFullWhitelabelConfig();

  return (
    <>
      <DealerVehicleSearch brandIds={whitelabelConfig.brandIds} dealerIds={whitelabelConfig.dealerIds} />
    </>
  );
};

export default Overview;
