import React from "react";
import WhitelabelType from "../constants/WhitelabelType";
import { useWhitelabelConfig } from "./WhitelabelConfig";
import WhitelabelFull from "./WhitelabelFull";
import WhitelabelBanner from "./WhitelabelBanner";
import WhitelabelVehicleRow from "./WhitelabelVehicleRow";

const WhitelabelTypeSwitch: React.FC = () => {
  const whitelabelConfig = useWhitelabelConfig();

  switch (whitelabelConfig.type) {
    case WhitelabelType.Banner:
      return <WhitelabelBanner />;
    case WhitelabelType.VehicleRow:
      return <WhitelabelVehicleRow />;
    default:
      return <WhitelabelFull />;
  }
};

export default WhitelabelTypeSwitch;
