import React from "react";
import WhitelabelLayout from "../../../modules/layout/components/WhitelabelLayout";
import Overview from "../../../whitelabel/vehicle-search/components/Overview";

const VehicleSearch: React.FC = () => (
  <WhitelabelLayout>
    <Overview />
  </WhitelabelLayout>
);

export default VehicleSearch;
