import React, { useEffect } from "react";
import { FullWhitelabelConfigType } from "../services/whitelabelConfigSchema";
import { KeyOfType } from "../../../modules/common/types/KeyOfType";
import { useFullWhitelabelConfig } from "./WhitelabelConfig";

const setCustomProperty = (name: string, value: string | undefined) => {
  if (value) {
    document.documentElement.style.setProperty(name, value);
  }
};

type ThemeProperties = KeyOfType<FullWhitelabelConfigType, string | undefined>;

const THEME_VARIABLES: Partial<Record<ThemeProperties, string>> = {
  themePageBackground: "--theme-page-background",
  themePageForeground: "--theme-page-foreground",

  themePrimary: "--theme-primary",
  themePrimaryHover: "--theme-primary-hover",
  themeOnPrimary: "--theme-on-primary",

  themeSecondary: "--theme-secondary",
  themeSecondaryHover: "--theme-secondary-hover",
  themeOnSecondary: "--theme-on-secondary",

  themeBoxBackground: "--theme-box-background",
  themeBorder: "--theme-border",
  themeMeta: "--theme-meta",
};

const THEME_VARIABLE_ENTRIES = Object.entries(THEME_VARIABLES) as [ThemeProperties, string][];

const WhitelabelTheme: React.FC = () => {
  const config = useFullWhitelabelConfig();

  useEffect(() => {
    THEME_VARIABLE_ENTRIES.forEach(([key, value]) => {
      setCustomProperty(value, config[key]);
    });
  }, [config]);
  return null;
};

export default WhitelabelTheme;
