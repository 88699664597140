import React from "react";
import i18next from "i18next";
import useIdFromSlugParam from "../../../modules/common/hooks/useIdFromSlugParam";
import { Params } from "../../../router/constants/Params";
import { useVehicleQuery } from "../../../modules/hygraph/services/vehicleDetailApi";
import FullPageLoader from "../../../modules/visual-components/components/FullPageLoader";
import NotFound from "../../../modules/common/components/NotFound";
import TopNav from "../../../modules/common/components/TopNav";
import WhitelabelTitleSync from "../../core/components/WhitelabelTitleSync";
import buildTitle from "../../../modules/common/helpers/buildTitle";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { useGetWhitelabelQuery } from "../../../modules/hygraph/services/whitelabelApi";
import { useFullWhitelabelConfig } from "../../core/components/WhitelabelConfig";
import VehicleDetail from "./VehicleDetail";

const Overview: React.FC = () => {
  const whitelabelConfig = useFullWhitelabelConfig();
  const vehicleId = useIdFromSlugParam(Params.slug_vehicleId);
  const { data: vehicle, isFetching } = useVehicleQuery(vehicleId);
  const { data } = useGetWhitelabelQuery(whitelabelConfig.id || "");

  const { whitelabelSearchPath } = usePathBuilder();

  // todo: fullpageloader might not be working as expected in whitelabel
  if (isFetching) return <FullPageLoader />;

  if (!vehicle) {
    return <NotFound />;
  }

  return (
    <>
      <WhitelabelTitleSync title={buildTitle(vehicle.title, false)} />
      <TopNav fallback={{ to: whitelabelSearchPath(), label: i18next.t("GOTO VEHICLESEARCH") }} />
      <VehicleDetail utmSource={data?.whitelabel?.utmSource} vehicle={vehicle} />
    </>
  );
};

export default Overview;
