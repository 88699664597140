import React from "react";
import { Route, Routes } from "react-router-dom";
import { Params } from "../constants/Params";
import VehicleSearch from "./whitelabel-pages/VehicleSearch";
import Vehicle from "./whitelabel-pages/Vehicle";

const WhitelabelRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<VehicleSearch />} path="/" />
      <Route element={<Vehicle />} path={`/vehicle/:${Params.slug_vehicleId}`} />
    </Routes>
  );
};

export default WhitelabelRoutes;
