import React, { useMemo } from "react";
import i18next from "i18next";
import { useVehicleRowWhitelabelConfig } from "../core/components/WhitelabelConfig";
import { CarCardContextType } from "../../modules/vehicle/components/CarCard";
import { setUtmParams, UtmCampaignType } from "../core/services/setUtmParams";
import usePathBuilder from "../../router/hooks/usePathBuilder";
import useStableFunction from "../../modules/common/hooks/useStableFunction";
import VehiclesCarousel from "../../modules/vehicle/components/VehiclesCarousel";
import AlgoliaVehicleSearchCarCard from "../../modules/vehicle/components/AlgoliaVehicleSearchCarCard";
import useVehicleSearchData from "../../modules/algolia/hooks/useVehicleSearchData";
import { useGetWhitelabelQuery } from "../../modules/hygraph/services/whitelabelApi";
import WhitelabelBannerContainer from "../core/components/WhitelabelBannerContainer";
import { ButtonAnchor } from "../../modules/visual-components/components/Button";

import { VEHICLE_TAB_INDEX } from "../../dealer-detail/constants/tab";
import { TrackingReferrer } from "../../modules/analytics/constants/TrackingReferrer";

const VehicleRow: React.FC = () => {
  const whitelabelConfig = useVehicleRowWhitelabelConfig();
  const { data } = useGetWhitelabelQuery(whitelabelConfig.id);
  const utmParams = {
    source: data?.whitelabel.utmSource,
    campaign: UtmCampaignType.VehicleRow,
  };
  const dealerId = whitelabelConfig.dealerId;

  const { homePath, vehicleDetailPath, dealerDetailPath } = usePathBuilder();

  const { vehicleData } = useVehicleSearchData({
    dealerRestrictions: [dealerId],
    pageSize: 10,
    searchTermString: whitelabelConfig.searchTermString,
  });

  const vehicleDetailPathBuilder = useStableFunction(({ id, slug }: { id: number; slug: string }) => {
    const detailPath = vehicleDetailPath({
      absolute: true,
      id,
      slug,
    });

    return setUtmParams(detailPath, utmParams);
  });

  const dealerDetailPathWithoutUtm = dealerDetailPath({
    absolute: true,
    id: dealerId,
    slug: `${dealerId}`,
    tab: VEHICLE_TAB_INDEX,
  });
  const dealerPath = setUtmParams(dealerDetailPathWithoutUtm, utmParams);

  const carCardContext: CarCardContextType = useMemo(
    () => ({
      linkBuilder: vehicleDetailPathBuilder,
      enableCompare: false,
      enableFavorites: false,
      linkTarget: "_blank",
    }),
    [vehicleDetailPathBuilder],
  );

  if (!vehicleData?.hits) {
    return null;
  }

  return (
    <WhitelabelBannerContainer
      allVehiclesLink={dealerPath}
      carCardContext={carCardContext}
      className="advertising-whitelabel"
      homeLink={setUtmParams(homePath({ absolute: true }), utmParams)}
    >
      <div className="advertising__list-wrap">
        <div className="advertising__item">
          <div className="advertising__right">
            <section className="section-carousel">
              <VehiclesCarousel title={i18next.t("SUITABLE VEHICLES")} totalItems={vehicleData.hits.length}>
                {vehicleData.hits.map(vehicle => (
                  <AlgoliaVehicleSearchCarCard
                    key={vehicle.vehicleId}
                    singleImage
                    trackingReferrer={TrackingReferrer.None}
                    vehicle={{ ...vehicle, promotionList: [] }}
                  />
                ))}
              </VehiclesCarousel>
            </section>
          </div>
        </div>
      </div>
      <div className="section-carousel__btn">
        <ButtonAnchor transparent href={dealerPath} target="_blank">
          {i18next.t("SHOW ALL VEHICLES")}
        </ButtonAnchor>
      </div>
    </WhitelabelBannerContainer>
  );
};

export default VehicleRow;
