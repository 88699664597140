import React from "react";
import { AugmentedDetailVehicle } from "../../../modules/vehicle/types/vehicle";
import VehicleDetailTabs from "../../../modules/vehicle/components/VehicleDetailTabs";
import EnergyData from "../../../modules/vehicle/components/EnergyData";
import VehicleDetailPageSkeleton from "../../../modules/vehicle/components/VehicleDetailPageSkeleton";
import DetailHero from "../../../modules/vehicle/components/DetailHero";
import BounceOffPopup, { InBounceOffContext } from "./BounceOffPopup";

type Props = {
  vehicle: AugmentedDetailVehicle;
  utmSource?: string;
};

const VehicleDetail: React.FC<Props> = ({ vehicle, utmSource }) => {
  return (
    <InBounceOffContext.Provider value={true}>
      <BounceOffPopup utmSource={utmSource} vehicle={vehicle} />
      <VehicleDetailPageSkeleton
        hideDealerProfileLink
        dealerRestriction={vehicle.dealer.insideId}
        hero={<DetailHero hideShare vehicle={vehicle} />}
        vehicle={vehicle}
        detailFragments={
          <>
            <VehicleDetailTabs vehicle={vehicle} />
            <section className="detail__section detail__section--energy-brand-model">
              <EnergyData vehicle={vehicle} />
            </section>
          </>
        }
      />
    </InBounceOffContext.Provider>
  );
};

export default VehicleDetail;
