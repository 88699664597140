import React, { StrictMode } from "react";
import { HashRouter } from "react-router-dom";
import WhitelabelConfig from "./whitelabel/core/components/WhitelabelConfig";
import WhitelabelLanguageConfig from "./whitelabel/core/components/WhitelabelLanguageConfig";
import WhitelabelTypeSwitch from "./whitelabel/core/components/WhitelabelTypeSwitch";
import WhitelabelHashUpdater from "./whitelabel/core/components/WhitelabelHashUpdater";

const WhitelabelApp: React.FC = () => {
  return (
    <StrictMode>
      <HashRouter>
        <WhitelabelConfig>
          <WhitelabelHashUpdater />
          <WhitelabelLanguageConfig>
            <WhitelabelTypeSwitch />
          </WhitelabelLanguageConfig>
        </WhitelabelConfig>
      </HashRouter>
    </StrictMode>
  );
};

export default WhitelabelApp;
