import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const WhitelabelLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="page-container">
      <main className="main" data-iframe-height={true}>
        <div className="container">{children}</div>
      </main>
    </div>
  );
};

export default WhitelabelLayout;
