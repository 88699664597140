import React, { useEffect } from "react";
import { MessageTypes, sendMessage } from "../services/messages";

type Props = {
  title: string | undefined;
};

const WhitelabelTitleSync: React.FC<Props> = ({ title }) => {
  useEffect(() => {
    if (title) {
      sendMessage(window.parent, {
        type: MessageTypes.TITLECHANGE,
        payload: title,
      });
    }
  }, [title]);
  return null;
};

export default WhitelabelTitleSync;
