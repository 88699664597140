import App from "../../../App";
import VehicleRow from "../../vehicle-row/VehicleRow";

const WhitelabelVehicleRow: React.FC = () => {
  return (
    <App>
      <VehicleRow />
    </App>
  );
};

export default WhitelabelVehicleRow;
