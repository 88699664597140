import i18next from "i18next";
import Logo from "../../../modules/layout/components/Logo";
import { CarCardContext, CarCardContextType } from "../../../modules/vehicle/components/CarCard";
import { ButtonAnchor } from "../../../modules/visual-components/components/Button";

const WhitelabelBannerContainer: React.FC<{
  carCardContext: CarCardContextType;
  homeLink: string;
  allVehiclesLink: string;
  className?: string;
  children: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}> = ({ carCardContext, homeLink, allVehiclesLink, className, children, containerRef }) => {
  return (
    <>
      <CarCardContext.Provider value={carCardContext}>
        <div ref={containerRef} className={`advertising-wrap ${className ? className : ""}`} data-iframe-height={true}>
          <div className="advertising__header">
            <Logo showSlogan context="advertising" link={homeLink} target="_blank" />
            <ButtonAnchor transparent href={allVehiclesLink} target="_blank">
              {i18next.t("SHOW ALL VEHICLES")}
            </ButtonAnchor>
          </div>
          <div className="advertising__list">{children}</div>
        </div>
      </CarCardContext.Provider>
    </>
  );
};

export default WhitelabelBannerContainer;
